import React, { useState } from 'react';
import { Button } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import Card from '../../../../../components/Cards/Card';
import ReviewField from '../../../../cli/pages/review/ReviewField';
import checkDate from '../../../utilities/checkDate';
import titleCase from '../../../utilities/titleCase';
import styled from 'styled-components';
import useTranslate from 'src/hooks/useTranslate';
const EVENT_INCREMENT = 6;
const StyledContainerDiv = styled('div') `
  flex-wrap: no-wrap;
  @media (max-width: 440px) {
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;
const StyledColumnDiv = styled('div') `
  @media (min-width: 440px) {
    margin:0px 2px 0px 2px;
    flexGrow: 1;

  }
  @media (max-width: 440px) {
    flex: 0 0 100%;
  }
`;
const StyledTwoColumnDiv = styled('div') `
  @media (min-width: 440px) {
    margin:0px 2px 0px 2px;
    flexGrow: 1;

  }
  @media (max-width: 440px) {
    flex: 0 0 48%;
  }
`;
const LeaveBalanceCard = ({ leaveBalanceList, }) => {
    const { t: ot } = useTranslation();
    const { t } = useTranslate();
    const [numEventsToDisplay, setNumEventsToDisplay] = useState(EVENT_INCREMENT);
    const Heading = (docType, docTitle) => {
        return <>{docType}&nbsp;-&nbsp;{docTitle}</>;
    };
    //Hours, Days, Weeks, Months
    const TimeRemainingTypeTranslationMap = {
        H: { english: "hours:minutes", spanish: "horas:minutos" },
        D: { english: "days", spanish: "días" },
        W: { english: "weeks", spanish: "semanas" },
        M: { english: "months", spanish: "meses" }
    };
    const timeAvl = (leaveBalance) => {
        if (leaveBalance.timeRemainingType != null) {
            return leaveBalance.timeRemain + " " + t(TimeRemainingTypeTranslationMap[leaveBalance.timeRemainingType]);
        }
        return leaveBalance.hoursRemain + " " + t(TimeRemainingTypeTranslationMap.H);
    };
    const timeUsed = (leaveBalance) => {
        if (leaveBalance.timeRemainingType != null) {
            return leaveBalance.timeUsed + " " + t(TimeRemainingTypeTranslationMap[leaveBalance.timeRemainingType]);
        }
        return leaveBalance.hoursUsed + " " + t(TimeRemainingTypeTranslationMap.H);
    };
    return (<div id='LeaveBalance-container' style={{ width: '100%', display: 'flex', marginBottom: '1rem' }}>
        <div id='leaveBalance-content' style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
          {leaveBalanceList.map((leaveBalance, i) => {
            if (i >= numEventsToDisplay) {
                return <></>;
            }
            return (<>
                <Card data-testid={`leaveBalanceCard`} key={`leaveBalanceCard`} heading={Heading(leaveBalance.program, leaveBalance.lve_pgm_cde)} fontSize='large' small={true}>
                  <StyledContainerDiv style={{ display: 'flex', justifyContent: 'space-between' }}>
                    
                    <StyledColumnDiv className='mb-2'>
                      <ReviewField label={titleCase(ot('programHoursUsedText'))} value={timeUsed(leaveBalance)}/>
                    </StyledColumnDiv>
                    
                    <StyledColumnDiv className='mb-2'>
                      <ReviewField data-testid={'programHoursRemainingText'} label={titleCase(ot('programHoursRemainingText'))} value={timeAvl(leaveBalance)}/>
                    </StyledColumnDiv>

                    {leaveBalance.timeRemainingType === "M" &&
                    <StyledColumnDiv className='mb-2'>
                        <ReviewField label={titleCase(ot('daysPerMonth'))} value={leaveBalance.daysInMonth}/>
                      </StyledColumnDiv>}

                    <StyledTwoColumnDiv className='mb-2'>
                      <ReviewField label={titleCase(ot('qualified'))} value={leaveBalance.lvePgmQualdCde === 'QF' ? 'Yes' : 'No'}/>
                    </StyledTwoColumnDiv>
                    
                    <StyledTwoColumnDiv className='mb-2'>
                      <ReviewField label={titleCase(ot('qualifiedThrough'))} value={checkDate(leaveBalance.lvePgmQualdThruDte)}/>
                    </StyledTwoColumnDiv>
                    
                    <StyledTwoColumnDiv className='mb-2'>
                      <ReviewField label={titleCase(ot('eligible'))} value={leaveBalance.lvePgmEligCde === 'EL' ? 'Yes' : 'No'}/>
                    </StyledTwoColumnDiv>
                    
                    <StyledTwoColumnDiv className='mb-2'>
                      <ReviewField label={titleCase(ot('eligibleThrough'))} value={checkDate(leaveBalance.lvePgmEligThruDte)}/>
                    </StyledTwoColumnDiv>
                    
                    {leaveBalance.program !== "Federal" &&
                    <StyledColumnDiv className='mb-2'>
                        <ReviewField label={titleCase(ot('state'))} value={leaveBalance.lvePgmStateCde}/>
                      </StyledColumnDiv>}
                  </StyledContainerDiv>
                </Card>
              </>);
        })}
          {numEventsToDisplay < leaveBalanceList?.length && (<Button style={{ justifyContent: 'center' }} buttonType='text' onClick={() => setNumEventsToDisplay(numEventsToDisplay + (EVENT_INCREMENT))}>
              Show more
            </Button>)}
          {numEventsToDisplay >= leaveBalanceList?.length && numEventsToDisplay > (EVENT_INCREMENT) && (<Button style={{ justifyContent: 'center' }} buttonType='text' onClick={() => setNumEventsToDisplay(numEventsToDisplay - (EVENT_INCREMENT))}>
              Show less
            </Button>)}
        </div>
      </div>);
};
export default LeaveBalanceCard;
